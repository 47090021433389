import apiFunc from '@/api/index'
import { computed, onMounted, ref } from 'vue'

const api = apiFunc()

export default function useIpNetwork () {
  /* DATA */
  const items = ref({})

  /* COMPUTED */
  const total = computed(() => {
    const arr = Object.entries(items.value).map(i => i[1])

    return arr.reduce((acc, item) => {
      return acc + item.model * item.price
    }, 0)
  })

  const receipt = computed(() => {
    const arr = Object.entries(items.value).map(i => i[1])
    const options = arr.map(item => {
      return {
        key: item.title,
        val: item.model + ' ' + item.unit
      }
    })

    return [
      {
        title: 'IP и Network',
        options
      }
    ]
  })

  const requestBody = computed(() => {
    const ip = items.value.ip
    const network = items.value.network

    if (!ip || !network) {
      return {}
    }

    return {
      ipId: ip.id,
      ipQuantity: ip.model,
      subnetId: network.id,
      subnetQuantity: network.model
    }
  })

  /* METHODS */
  const fetchItems = async () => {
    const res = await api.services.network()
    const data = res.data ?? {}
    const ip = data.ip ?? {}
    const network = data.subnet ?? {}

    items.value = {
      ip: {
        id: ip.id,
        title: 'IP',
        min: ip.min,
        max: ip.max,
        step: ip.step,
        price: ip.price,
        unit: 'шт',
        model: ip.min
      },
      network: {
        id: network.id,
        title: 'Network',
        min: network.min,
        max: network.max,
        step: network.step,
        price: network.price,
        unit: 'шт',
        model: network.min
      }
    }
  }

  onMounted(async () => {
    await fetchItems()
  })

  return {
    items,
    total,
    receipt,
    requestBody
  }
}
