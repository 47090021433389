import apiFunc from '@/api/index'
import { ref, computed, onMounted } from 'vue'

const api = apiFunc()

/* Костыль из-за неправильной реализации макета и бека */
/* < ----------- Переделать в будующем ----------- > */
const standartID = 1
const goldID = 3
/* < ----------- Переделать в будующем ----------- > */

export default function useCloudInfrastructure () {
  /* DATA */
  const selected = ref([standartID, goldID])
  const configs = ref([])

  /* COMPUTED */
  const total = computed(() => {
    let total = 0

    for (const id of selected.value) {
      const currentRate = configs.value.find(i => i.id === id)

      if (!currentRate) { continue }

      for (const key in currentRate.rows) {
        const curItem = currentRate.rows[key]

        if (curItem.checked === false) { continue }

        total += curItem.price * curItem.model
      }
    }

    return total
  })

  const receipt = computed(() => {
    if (!configs.value.length) { return [] }

    return selected.value.map(id => {
      const currentItem = configs.value.find(i => i.id === id)
      const options = []

      for (const key in currentItem.rows) {
        if (currentItem.rows[key].checked !== false) {
          options.push({
            key: currentItem.rows[key].title,
            val: currentItem.rows[key].model + ' Гб.'
          })
        }
      }

      return {
        title: currentItem.title,
        options
      }
    })
  })

  const requestBody = computed(() => {
    return configs.value.map(config => {
      const checked = selected.value.includes(config.id)
      if (!checked) { return }

      const configResult = {}
      const rows = config.rows ?? {}

      for (const key in rows) {
        const value = rows[key]

        if (value?.checked !== false) {
          configResult[key] = {
            id: value.id,
            quantity: value.model
          }
        }
      }

      configResult.id = config.id

      return configResult
    }).filter(i => i)
  })

  /* METHODS */
  const fetchInitialInfo = async () => {
    const res = await api.services.fetchInfrastructure()
    const list = res.data?.list ?? []

    const standart = list.find(i => i.id === 1)
    const gold = list.find(i => i.id === 3)

    configs.value = [
      {
        id: standartID,
        title: 'Стандарт',
        rows: {
          cpu: {
            title: 'vCPU',
            icon: 'chip',
            id: standart.cpu.id,
            model: standart.cpu.min,
            min: standart.cpu.min,
            max: standart.cpu.max,
            step: standart.cpu.step,
            price: standart.cpu.price
          },
          ram: {
            title: 'RAM',
            icon: 'ram',
            id: standart.ram.id,
            model: standart.ram.min,
            min: standart.ram.min,
            max: standart.ram.max,
            step: standart.ram.step,
            price: standart.ram.price
          },
          ssd: {
            title: 'SSD',
            checked: true,
            id: standart.ssdDisk.id,
            model: standart.ssdDisk.min,
            min: standart.ssdDisk.min,
            max: standart.ssdDisk.max,
            step: standart.ssdDisk.step,
            price: standart.ssdDisk.price
          },
          sas: {
            title: 'SAS',
            checked: true,
            id: standart.sasDisk.id,
            model: standart.sasDisk.min,
            min: standart.sasDisk.min,
            max: standart.sasDisk.max,
            step: standart.sasDisk.step,
            price: standart.sasDisk.price
          }
        }
      },
      {
        id: goldID,
        theme: 'gold',
        title: 'Gold',
        rows: {
          cpu: {
            title: 'vCPU',
            icon: 'chip',
            id: gold.cpu.id,
            model: gold.cpu.min,
            min: gold.cpu.min,
            max: gold.cpu.max,
            step: gold.cpu.step,
            price: gold.cpu.price
          },
          ram: {
            title: 'RAM',
            icon: 'ram',
            id: gold.ram.id,
            model: gold.ram.min,
            min: gold.ram.min,
            max: gold.ram.max,
            step: gold.ram.step,
            price: gold.ram.price
          },
          ssd: {
            title: 'SSD',
            checked: true,
            id: gold.ssdDisk.id,
            model: gold.ssdDisk.min,
            min: gold.ssdDisk.min,
            max: gold.ssdDisk.max,
            step: gold.ssdDisk.step,
            price: gold.ssdDisk.price
          },
          sas: {
            title: 'SAS',
            checked: true,
            id: gold.sasDisk.id,
            model: gold.sasDisk.min,
            min: gold.sasDisk.min,
            max: gold.sasDisk.max,
            step: gold.sasDisk.step,
            price: gold.sasDisk.price
          }
        }
      }
    ]
  }

  const onCheckConfig = (bool, id) => {
    const exist = selected.value.includes(id)

    if (exist) {
      selected.value = selected.value.filter(i => i !== id)
    } else {
      selected.value.push(id)
    }

    if (selected.value.length === 0) {
      if (id === standartID) {
        selected.value.push(goldID)
      } else if (id === goldID) {
        selected.value.push(standartID)
      }
    }
  }

  /* MOUNTED */
  onMounted(() => {
    try {
      fetchInitialInfo()
    } catch (e) {
      console.log(e)
    }
  })

  return {
    selected,
    configs,
    total,
    receipt,
    requestBody,
    onCheckConfig
  }
}
