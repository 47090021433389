<template>
  <main class="infrastructure">
    <AppPreview
        title="Инфраструктура в облаке"
        text="Аренда вычислительных мощностей и облачных хранилищ для вашего бизнеса."
    >
      <img src="/images/Infrastructure.png" alt="preview">
    </AppPreview>

    <section class="infrastructure__info">
      <div class="container">
        <h2>
          Инфраструктура будущего - в облаке
        </h2>
        <div class="desc">
          В эру победившего «клауда» вопрос перехода от информационных систем on-premise к подлинно облачной модели становится определяющим. Поэтому Вам будут нужны проверенные и безопасные сервисы для решения конкретных задач, созданные заказчиками и партнерами.
        </div>
      </div>
    </section>

    <section class="infrastructure-config">
      <div class="container">
        <h2>Гибкая настройка конфигурации</h2>

        <div class="infrastructure-config__items">
          <div
              v-for="config of infrastructureConfigs"
              :key="config.id"
          >
            <BaseSwitches
                :modelValue="infrastructureSelected.includes(config.id)"
                :label="config.title"
                :theme="config.theme"
                @update:modelValue="onCheckConfig($event, config.id)"
            />

            <ConfigRow
                v-for="(item, key) in config.rows"
                :item="item"
                :key="key"
                v-model="item.model"
                v-model:checked="item.checked"
                :title="item.title"
                :icon="item.icon"
                :min="item.min"
                :max="item.max"
                :step="item.step"
                :theme="config.theme"
                :disabled="!infrastructureSelected.includes(config.id)"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="ip-network">
      <div class="container">
        <h2>IP и Network</h2>
        <div class="ip-network__calc">
          <div class="left">
            <div
                v-for="row of netItems"
                :key="row.id"
                class="row"
            >
              <div class="label">{{  row.title  }}</div>
              <div class="range">
                <BaseInputRange
                    v-model="row.model"
                    :min="row.min"
                    :max="row.max"
                    :step="row.step"
                />
              </div>
              <div class="count">{{ row.model }} {{ row.unit }}.</div>
            </div>
          </div>
          <div class="right">
            <div class="right__item">
              <p class="placeholder">
                EDGE
              </p>
              <BaseSelect
                  v-model="edge.model"
                  :options="edge.options"
              />
            </div>
            <div class="right__item">
              <p class="placeholder">
                Лицензия
              </p>
              <BaseButton
                  block
                  style="text-align: left"
                  @click="$refs.licensesModal.open()"
              >
                Выберите из списка
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="backup-section">
      <div class="container">
        <BaseSwitches v-model="backupEnabled">
          <template #label>
            <h2>{{ backupExtraData.title }}</h2>
          </template>
        </BaseSwitches>

        <div class="desc">
          {{ backupExtraData.desc }}
        </div>

        <div class="calc">
          <div
              v-for="(item, index) of backupItems"
              :key="index"
              class="calc-row"
          >
            <p class="calc-row__title">{{ item.title }}</p>
            <BaseInputRange
                v-model="item.model"
                :min="item.min"
                :max="item.max"
                :step="item.step"
                :disabled="!backupEnabled"
                class="calc-row__slider"
            />
            <p class="calc-row__val">{{ item.model }} {{ item.unit }}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="cloud-store">
      <div class="container">
        <BaseSwitches v-model="cloudStoreEnabled">
          <template #label>
            <h2>{{ cloudStoreExtraData.title }}</h2>
          </template>
        </BaseSwitches>

        <div class="cloud-store-items">
          <CalcItem
              v-for="item of cloudStoreList"
              :key="item.id"
              v-model:current="item.current"
              v-model:enabled="item.enabled"
              :min="item.min"
              :max="item.max"
              :step="item.step"
              :label="item.label"
              :unit="item.unit"
              :globalEnabled="cloudStoreEnabled"
          />
        </div>
      </div>
    </section>

    <section
        class="container"
        style="margin-top: 60px;"
    >
      <PanelTotal
          :price="globalTotal"
          @open="$refs.requestModal.open()"
      />
    </section>

    <BaseModal ref="licensesModal">
      <div class="licenses container" data-uid="licenses">
        <h2>Выберите лицензию</h2>
        <div
            v-for="(item, index) of license"
            :key="index"
            class="row"
        >
          <div class="top">
            <BaseCheckbox
                v-model="item.checked"
                :label="item.title"
            />
            <p class="unit">
              {{ item.model }} {{item.unit }}
            </p>
          </div>
          <div class="bottom">
            <BaseInputRange
                v-model="item.model"
                :min="item.min"
                :max="item.max"
                :step="item.step"
                :disabled="!item.checked"
            />
          </div>
        </div>

        <BaseButton
            class="btn"
            @click="$refs.licensesModal.close()"
        >
          Применить
        </BaseButton>
      </div>
    </BaseModal>

    <ModalRequest
        :total="globalTotal"
        ref="requestModal"
        @submit="onSubmit"
    >
      <AppReceipt :receipt="globalReceipt" />
    </ModalRequest>

    <ModalThank ref="modalThank" />
  </main>
</template>

<script>
import useCloudInfrastructure from '@/composables/useCloudInfrastructure'
import useBackup from '@/composables/useBackup'
import useCloudStore from '@/composables/useCloudStore'
import useIpNetwork from '@/composables/useIpNetwork'
import useEdgeLicense from '@/composables/useEdgeLicense'

import AppPreview from '@/components/AppPreview'
import ConfigRow from '@/components/ConfigRow'
import CalcItem from '@/components/CalcItem'
import PanelTotal from '@/components/PanelTotal'
import ModalRequest from '@/components/ModalTemplates/ModalRequest'
import AppReceipt from '@/components/AppReceipt'
import BaseSwitches from '@/components/Base/BaseSwitches'
import BaseInputRange from '@/components/Base/BaseInputRange'
import BaseSelect from '@/components/Base/BaseSelect'
import BaseButton from '@/components/Base/BaseButton'
import BaseModal from '@/components/Base/BaseModal'
import BaseCheckbox from '@/components/Base/BaseCheckbox'
import ModalThank from '@/components/ModalTemplates/ModalThank'

export default {
  name: 'PageInfrastructure',
  components: {
    ModalThank,
    BaseCheckbox,
    BaseModal,
    BaseButton,
    BaseSelect,
    AppPreview,
    ConfigRow,
    CalcItem,
    PanelTotal,
    ModalRequest,
    AppReceipt,
    BaseSwitches,
    BaseInputRange
  },
  setup () {
    const {
      selected: infrastructureSelected,
      configs: infrastructureConfigs,
      total: infrastructureTotal,
      receipt: infrastructureReceipt,
      requestBody: infrastructureRequestBody,
      onCheckConfig
    } = useCloudInfrastructure()

    const {
      items: netItems,
      total: netTotal,
      receipt: netReceipt,
      requestBody: netRequestBody
    } = useIpNetwork()

    const {
      edge,
      license,
      total: EdgeLicenseTotal,
      requestBody: EdgeLicenseRequestBody,
      receipt: EdgeLicenseReceipt
    } = useEdgeLicense()

    const {
      enabled: backupEnabled,
      receipt: backupReceipt,
      total: backupTotal,
      extraData: backupExtraData,
      requestBody: backupRequestBody,
      items: backupItems
    } = useBackup()

    const {
      enabled: cloudStoreEnabled,
      extraData: cloudStoreExtraData,
      receipt: cloudStoreReceipt,
      list: cloudStoreList,
      requestBody: cloudStoreRequestBody,
      price: cloudStoreTotal
    } = useCloudStore()

    return {
      infrastructureSelected,
      infrastructureConfigs,
      infrastructureTotal,
      infrastructureReceipt,
      infrastructureRequestBody,
      onCheckConfig,

      netItems,
      netTotal,
      netReceipt,
      netRequestBody,

      edge,
      license,
      EdgeLicenseTotal,
      EdgeLicenseRequestBody,
      EdgeLicenseReceipt,

      backupEnabled,
      backupExtraData,
      backupTotal,
      backupReceipt,
      backupRequestBody,
      backupItems,

      cloudStoreEnabled,
      cloudStoreExtraData,
      cloudStoreReceipt,
      cloudStoreList,
      cloudStoreRequestBody,
      cloudStoreTotal
    }
  },
  computed: {
    globalTotal () {
      return this.infrastructureTotal +
          this.backupTotal +
          this.cloudStoreTotal +
          this.netTotal +
          this.EdgeLicenseTotal
    },
    globalReceipt () {
      return [
        ...this.infrastructureReceipt,
        ...this.netReceipt,
        ...this.EdgeLicenseReceipt,
        ...this.backupReceipt,
        ...this.cloudStoreReceipt
      ]
    },
    globalRequestBody () {
      return {
        service: this.infrastructureRequestBody,
        network: this.netRequestBody,
        backup: this.backupRequestBody,
        cloudStorage: this.cloudStoreRequestBody,
        price: this.globalTotal,
        ...this.EdgeLicenseRequestBody
      }
    }
  },
  methods: {
    async onSubmit (e) {
      const { surname, name, patron, companyName, email, phone } = e

      try {
        const res = await this.$api.common.sendClientData({
          surname, name, patronymic: patron, companyName, email, phone
        })
        const applicationId = res.data?.applicationId

        await this.$api.services.sendInfrastructure({
          applicationId,
          ...this.globalRequestBody
        })

        this.$refs.requestModal.close()
        this.$refs.requestModal.clear()
        this.$refs.modalThank.open()
      } catch (e) {
        console.log(e)
        alert('Произошла ошибка!')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.infrastructure {
  &__info {
    margin-top: 40px;

    .desc {
      font-size: 18px;
      line-height: 1.6;
      margin-top: 40px;
    }
  }

  &-config {
    margin-top: 80px;

    &__items {
      margin-top: 24px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;

      @media screen and (max-width: $media-md) {
        grid-template-columns: 1fr;
      }
    }
  }

  .ip-network {
    margin-top: 80px;

    &__calc {
      margin-top: 14px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px;

      @media screen and (max-width: $media-md) {
        grid-template-columns: 1fr;
        grid-gap: 30px;
      }

      .left, .right {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: 70px;
        align-items: center;
      }

      .left {
        .row {
          display: grid;
          grid-template-columns: 90px 1fr 90px;
          grid-column-gap: 40px;
          align-items: center;

          @media screen and (max-width: $media-md) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
          }

          .label {
            text-align: right;
            font-weight: 500;
            font-size: 18px;

            @media screen and (max-width: $media-md) {
              text-align: left;
            }
          }

          .range {
            @media screen and (max-width: $media-md) {
              grid-row-start: 2;
              grid-column: 1/3;
            }
          }

          .count {
            font-weight: 500;
            font-size: 18px;
            color: #008ACB;

            @media screen and (max-width: $media-md) {
              text-align: right;
            }
          }
        }
      }

      .right {
        @media screen and (max-width: $media-md) {
          grid-auto-rows: initial;
          align-items: initial;
        }

        &__item {
          display: grid;
          grid-template-columns: 1fr 3fr;
          align-items: center;

          @media screen and (max-width: $media-md) {
            text-align: left;
            grid-template-columns: 1fr;
            grid-gap: 10px;

            &:not(:first-child) {
              margin-top: 20px;
            }
          }

          .placeholder {
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
    }
  }

  .backup-section {
    margin-top: 80px;

    // Стили находятся в файле layouts/default.vue
  }

  .cloud-store {
    margin-top: 80px;

    &-items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      margin-top: 22px;

      @media screen and (max-width: $media-md) {
        grid-template-columns: 1fr;
      }
    }
  }
}
</style>

<style lang="scss">
.licenses[data-uid="licenses"] {
  .row {
    margin-top: 30px;

    .top {
      display: grid;
      grid-template-columns: 1fr 1fr;
      font-weight: 500;
      font-size: 18px;

      .unit {
        text-align: right;
        color: $primary-color;
      }
    }
    .bottom {
      margin-top: 20px;
    }
  }

  .btn {
    margin-top: 20px;
  }
}
</style>
