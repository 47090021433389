import apiFunc from '@/api/index'
import { computed, onMounted, ref } from 'vue'

const api = apiFunc()

export default function useIpNetwork () {
  /* DATA */
  const edge = ref({
    model: null,
    options: []
  })
  const license = ref([])

  /* COMPUTED */
  const total = computed(() => {
    let edgeTotal = 0

    if (edge.value.model !== null) {
      const arr = edge.value.options ?? []
      const curEl = arr.find(i => i.value === edge.value.model)

      edgeTotal = curEl.price
    }

    const licenseTotal = license.value.reduce((acc, item) => {
      if (item.checked === false) {
        return acc
      }

      return acc + item.model * item.price
    }, 0)

    return edgeTotal + licenseTotal
  })

  const receipt = computed(() => {
    const result = []

    if (edge.value.model) {
      const arr = edge.value.options ?? []
      const curEl = arr.find(i => i.value === edge.value.model)

      result.push({
        key: 'EDGE',
        val: curEl?.text
      })
    }

    const licenseReceipt = license.value.map(item => {
      if (!item.checked) { return }

      return {
        key: item.title,
        val: item.model + ' ' + item.unit
      }
    }).filter(i => i)

    result.push(...licenseReceipt)
    return [
      {
        title: 'EDGE и Лицензия',
        options: result
      }
    ]
  })

  const requestBody = computed(() => {
    const result = {
      license: []
    }

    if (edge.value.model !== null) {
      result.edge = {
        id: edge.value.model
      }
    }

    result.license = license.value.map(item => {
      if (!item.checked) { return }

      return {
        id: item.id,
        quantity: item.model
      }
    }).filter(i => i)

    if (result.license.length === 0) {
      result.license = null
    }

    return result
  })

  /* METHODS */
  const fetchItems = async () => {
    const res = await api.services.infrastructureLicenses()
    const data = res.data

    const edgeFromApi = data.edge ?? []
    const licenseFromApi = data.license ?? []

    edge.value.options = edgeFromApi.map(item => {
      return {
        value: item.id,
        text: item.title,
        price: item.price
      }
    })

    license.value = licenseFromApi.map(item => {
      return {
        id: item.id,
        title: item.title,
        min: item.min,
        max: item.max,
        step: item.step,
        price: item.price,
        checked: false,
        model: item.min,
        unit: 'шт.'
      }
    })
  }

  onMounted(async () => {
    await fetchItems()
  })

  return {
    edge,
    license,
    total,
    receipt,
    requestBody
  }
}
